import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { DesktopToastService } from '../../../services/desktop-toast.service';
import {
    AssetsTableComponent
} from '../../../components/assets/assets-and-plans/assets-table/assets-table.component';
import { LocalEventService, } from '../../../services/local-event.service';
import { SelectionService } from '../../../services/selection.service';
import { ConfirmService } from '../../../services/confirm.service';
import {
    AuditEditDialogComponent,
    AuditEditDialogData
} from "../../../component-dialogs/audit-edit-dialog/audit-edit-dialog.component";
import { UserroleauditStatisticsDialogComponent } from '../../../component-dialogs/userroleaudit-statistics-dialog/userroleaudit-statistics-dialog.component';
import { UserRoleAuditOutput } from 'projects/shared/src/lib/graphql/output/userRoleAuditOutput';

type Content = 'audits';

@Component({
    selector: 'security-app-ag-sidebar',
    templateUrl: './security-ag-sidebar.component.html',
    styleUrls: ['./security-ag-sidebar.component.scss'],
})
export class SecurityAgSidebarComponent implements OnInit {
    context: AssetsTableComponent | undefined;

    showContent: Content | undefined;
    audits: UserRoleAuditOutput[] = [];
    auditSelected: UserRoleAuditOutput | undefined;
    auditDragSituation = new Map<string, number>();
    auditActivity = new Map<string, boolean>();

    constructor(
        public apollo: Apollo,
        public localeService: LocaleService,
        public toastService: DesktopToastService,
        @Inject(MAT_DATE_LOCALE) public locale: string,
        private matDialog: MatDialog,
        private localEventService: LocalEventService,
        private selectionService: SelectionService,
        private confirmService: ConfirmService
    ) {
    }

    ngOnInit(): void {
    }

    async onNavigation(showContent: Content | undefined) {
        console.log('onNavigation', showContent)
        if (this.showContent === showContent) {
            this.showContent = undefined;
            return;
        }
        await this.loadAudits()
        this.showContent = showContent;
    }

    private async loadAudits() {
        const query = gql`
      query UserRoleAudits {
        userRoleAudits {
              id
              tenantId
              name
              description
              completedAt
              completedBy
              createdAt
              createdBy
        }
      }
    `;
        const resp = await lastValueFrom(this.apollo.query<{
            userRoleAudits: UserRoleAuditOutput[]
        }>({ query, fetchPolicy: 'no-cache' }));
        //make the userRoleAudits a copy of the response and not readonly
        this.audits = resp.data.userRoleAudits.map(a => ({ ...a }))
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());


    }

    onAuditClick(audit: UserRoleAuditOutput) {

    }

    onAuditEditClick(event: MouseEvent, audit: UserRoleAuditOutput) {
        event.stopPropagation();

        const data: AuditEditDialogData = {
            name: audit.name,
            description: audit.description ?? ''
        };

        const dialog = this.matDialog.open(AuditEditDialogComponent, {
            autoFocus: false,
            data,
            minWidth: 400,
        });

        dialog.afterClosed().subscribe(async (result: AuditEditDialogData) => {
            if (!result) {
                return
            }
            const auditCopy = { ...audit }
            auditCopy.description = result.description;
            auditCopy.name = result.name;
            await this.updateAudit(auditCopy)
        });
    }

    private async updateAudit(audit: UserRoleAuditOutput) {
        try {
            const mutation = gql`
          mutation UpdateUserRoleAudit($id: String!, $data: UserRoleAuditInputUpdate!) {
            updateUserRoleAudit(id: $id, data: $data) {
              name
              description
            }
          }
      `
            await lastValueFrom(this.apollo.mutate<{ updateUserRoleAudit: UserRoleAuditOutput }>({
                mutation,
                variables: {
                    id: audit.id,
                    data: {
                        name: audit.name,
                        description: audit.description
                    }
                }
            }))
            let find = this.audits.find(a => a.id === audit.id);
            find!.name = audit.name
            find!.description = audit.description
            this.toastService.info('Audit updated')
        } catch (e) {
            console.error(e)
            this.toastService.error('Error updating audit')
        }
    }

    async onAuditDeleteClick(event: MouseEvent, audit: UserRoleAuditOutput) {
        event.stopPropagation();

        this.confirmService.open(
            'Are you sure?',
            'Do you really want to delete this audit and all related data?',
            async () => {
                const variables = {
                    id: audit.id,
                };
                try {
                    const resp = await firstValueFrom(
                        this.apollo.mutate({
                            mutation: gql`
                mutation DeleteUserRoleAudit($id: String!) {
                  deleteUserRoleAudit(id: $id) {
                    name
                  }
                }
              `,
                            variables,
                        })
                    );
                    this.audits = this.audits.filter((a) => a.id !== audit.id);
                    this.toastService.info('Audit deleted');
                } catch (e) {
                    console.error(e);
                    this.toastService.error('Error deleting audit');
                }
            }
        );
    }

    onAuditCompleteClick(event: MouseEvent, audit: UserRoleAuditOutput) {
        event.stopPropagation();
        this.confirmService.open(
            'Are you sure?',
            `Setting an audit to 'completed' cannot be revoked.`,
            async () => {
                const variables = {
                    id: audit.id,
                };

                try {
                    const resp = await firstValueFrom(
                        this.apollo.mutate<{ completeUserRoleAudit: UserRoleAuditOutput }>({
                            mutation: gql`
                mutation CompleteUserRoleAudit($id: String!) {
                  completeUserRoleAudit(id: $id) {
                    completedAt
                    completedBy
                  }
                }
              `,
                            variables,
                        })
                    );
                    console.log(resp)
                    audit.completedAt = resp.data?.completeUserRoleAudit?.completedAt;
                    audit.completedBy = resp.data?.completeUserRoleAudit?.completedBy;
                    this.toastService.info('Audit completed');
                } catch (e) {
                    console.error(e);
                    this.toastService.error('Error completing audit');
                }
            }
        );
    }

    onAuditStatisticsClick(event: MouseEvent, audit: UserRoleAuditOutput) {
        event.stopPropagation();

        const dialog = this.matDialog.open(UserroleauditStatisticsDialogComponent, {
            data: { userRoleAuditId: audit.id, userRoleAuditName: audit.name },
            autoFocus: false,
            minWidth: 800,
        });
    }
}
