<div *ngIf="showContent" class="content">
    <!-- ###################### -->
    <!-- SHOW Audits START -->
    <!-- ###################### -->
    <ng-container appNotReader *ngIf="showContent === 'audits'">
        <div *ngIf="audits.length === 0; else hasAudits" style="width: 330px; font-style: italic"
            class="mt-normal ml-normal">
            No audits available.
        </div>

        <ng-template #hasAudits>
            <div class="flex-column">
                <div *ngFor="let audit of audits" class="inventory-item prevent-select"
                    [class.active]="auditSelected?.id === audit.id" style="min-width: 330px; max-width: 360px"
                    (click)="onAuditClick(audit)">
                    <!-- ACTIVITY OVERLAY -->
                    <div class="activity-overlay" *ngIf="auditActivity.get(audit.id)">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>

                    <!-- DRAG AND DROP OVERLAY -->
                    <div class="drag-overlay" *ngIf="(auditDragSituation.get(audit.id) ?? 0) > 0">
                        <div class="grid">
                            <!-- ADD -->
                            <div class="add">
                                <span class="material-symbols-outlined" style="margin-top: 4px; pointer-events: none">
                                    add
                                </span>
                            </div>

                            <!-- REMOVE -->
                            <div class="remove">
                                <span class="material-symbols-outlined" style="margin-top: 4px; pointer-events: none">
                                    delete
                                </span>
                            </div>
                        </div>
                    </div>

                    <!-- REGULAR ITEM ELEMENT -->
                    <div class="left" style="pointer-events: none">
                        <div class="flex-row align-items-center gap-normal">
                            <span>{{ audit.name }}</span>
                            <span *ngIf="audit.completedAt" class="material-symbols-outlined color-green">
                                task_alt
                            </span>
                        </div>
                        <div *ngIf="audit.description" class="font-size-12" style="opacity: 0.87; overflow-x: hidden">
                            {{ audit.description }}
                        </div>
                        <div class="flex-row align-items-center gap-normal font-size-12 mat-body nowrap">
                            <span>
                                {{
                                audit.createdAt
                                | date : localeService.datetimePipeString(locale)
                                }}
                            </span>
                            <lib-user [oId]="audit.createdBy" variant="phone" [inheritAppearance]="true"></lib-user>
                        </div>
                    </div>

                    <div class="top-actions">
                        <span *ngIf="!audit.completedAt" matTooltip="Set to 'completed'" matTooltipPosition="above"
                            matRipple class="material-symbols-outlined item"
                            (click)="onAuditCompleteClick($event, audit)">
                            task_alt
                        </span>
                        <span matTooltip="Show Statistics" matTooltipPosition="above" matRipple
                            *ngIf="audit.completedAt" class="material-symbols-outlined item"
                            (click)="onAuditStatisticsClick($event, audit)">
                            bar_chart
                        </span>
                        <span matTooltip="Edit" matTooltipPosition="above" matRipple
                            class="material-symbols-outlined fill item" *ngIf="audit.completedAt === null"
                            (click)="onAuditEditClick($event, audit)">
                            stylus
                        </span>
                    </div>

                    <div class="right-actions">
                        <span class="material-symbols-outlined item" matTooltip="Delete" matTooltipPosition="above"
                            (click)="onAuditDeleteClick($event, audit)">
                            delete
                        </span>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
    <!-- #################### -->
    <!-- SHOW AUDITS END -->
    <!-- #################### -->
</div>

<div class="navigation">
    <div appNotReader class="item" (click)="onNavigation('audits')" [class.active]="showContent === 'audits'">
        <mat-icon fontSet="material-symbols-outlined">assignment</mat-icon>
        <span class="text" style="margin-top: 24px">Audits</span>
        <mat-icon *ngIf="auditSelected" fontSet="material-symbols-outlined" style="margin-top: 20px" color="warn">
            priority_high
        </mat-icon>
    </div>
</div>