import { AssetReminderOutput } from 'projects/shared/src/lib/graphql/output/assetReminderOutput';
import { AssetRemindersQueryRoot } from 'projects/shared/src/lib/graphql/crud/assetReminder';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';

export type ViewAssetRemindersDialogData = {
    assetId: string;
};

@Component({
    selector: 'app-view-asset-reminders-dialog',
    templateUrl: './view-asset-reminders-dialog.component.html',
    styleUrls: ['./view-asset-reminders-dialog.component.scss']
})
export class ViewAssetRemindersDialogComponent implements OnInit {

    loading = false;
    assetReminders: AssetReminderOutput[] = [];
    displayedColumns: string[] = ['reminderDate', 'reminderSent', 'comment', 'createdBy'];

    constructor(
        private _dialogRef: MatDialogRef<ViewAssetRemindersDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ViewAssetRemindersDialogData,
        @Inject(MAT_DATE_LOCALE) public locale: string,
        private _toastService: DesktopToastService,
        public localeService: LocaleService,
        private _apollo: Apollo,
    ) { }

    ngOnInit(): void {
        this.#loadData();
    }

    closeDialog() {
        this._dialogRef.close(undefined);
    }

    async #loadData() {
        this.loading = true;
        try {
            const result = await firstValueFrom(
                this._apollo.query<AssetRemindersQueryRoot>({
                    query: gql`
                        query AssetReminders($tenantAssetId: String!) {
                            assetReminders(tenantAssetId: $tenantAssetId) {
                                id
                                tenantId
                                tenantAssetId
                                reminderDate
                                reminderSent
                                comment
                                createdAt
                                createdBy
                            }
                        }`,
                    variables: { tenantAssetId: this.data.assetId },
                    fetchPolicy: 'network-only',
                })
            );

            this.assetReminders = result.data.assetReminders.sort((a, b) => { return new Date(b.reminderDate).getTime() - new Date(a.reminderDate).getTime(); });
        } catch (error) {
            this._toastService.error(new CatchError(error).message, 'ERROR');
            this._dialogRef.close(undefined);
        } finally {
            this.loading = false;
        }
    }

}
