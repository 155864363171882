import { UserRoleAuditStatisticOutput } from 'projects/shared/src/lib/graphql/output/userRoleAuditStatisticOutput';
import { DesktopToastService } from '../../services/desktop-toast.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { Component, Inject, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { RoleLookup } from 'projects/shared/src/lib/graphql/output/roleOutput';

interface DiffViewData {
    snapshotDiffState?: string | null;
    roleId: string;
    userEmail: string;
    createdAt: Date;
    createdByEmail: string;
}

@Component({
    selector: 'app-userroleaudit-statistics-dialog',
    templateUrl: './userroleaudit-statistics-dialog.component.html',
    styleUrls: ['./userroleaudit-statistics-dialog.component.scss']
})
export class UserroleauditStatisticsDialogComponent implements OnInit {
    loading = false;
    userRoleAuditStatistic!: UserRoleAuditStatisticOutput;
    diffViewData!: DiffViewData[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { userRoleAuditId: string, userRoleAuditName: string },
        private matDialog: MatDialogRef<UserroleauditStatisticsDialogComponent>,
        private toastService: DesktopToastService,
        private apollo: Apollo,
    ) { }

    ngOnInit(): void {
        this.#loadData();
    }

    async #loadData() {
        this.loading = true;
        try {
            const result = await firstValueFrom(
                this.apollo.query<{ userRoleAuditStatistic: UserRoleAuditStatisticOutput }>({
                    query: gql`
                        query UserRoleAuditStatistic($userRoleAuditId: String!) {
                            userRoleAuditStatistic(userRoleAuditId: $userRoleAuditId) {
                                userRoleAuditId
                                diffData {
                                    id
                                    tenantId
                                    userRoleAuditId
                                    snapshotType
                                    snapshotDiffState
                                    roleId
                                    userOid
                                    userEmail
                                    createdAt
                                    createdBy
                                    createdByEmail
                                }
                                oldSums {
                                    userCountFull
                                    roleTenantAdminCount
                                    roleAssetArchitectCount
                                    roleAssetManagerCount
                                    roleAssetReaderCount
                                    roleAssetUserCount
                                }
                                newSums {
                                    userCountFull
                                    roleTenantAdminCount
                                    roleAssetArchitectCount
                                    roleAssetManagerCount
                                    roleAssetReaderCount
                                    roleAssetUserCount
                                }
                            }
                    }`,
                    variables: { userRoleAuditId: this.data.userRoleAuditId, },
                    fetchPolicy: 'no-cache',
                })
            );

            this.userRoleAuditStatistic = result.data.userRoleAuditStatistic;
            this.diffViewData = this.userRoleAuditStatistic.diffData?.map((diff) => {
                return ({
                    snapshotDiffState: diff.snapshotDiffState,
                    roleId: RoleLookup.get(diff.roleId) ?? "Unknown",
                    userEmail: (diff.userEmail?.includes('@') ? diff.userEmail.split('@')[0] : diff.userEmail) ?? "[Deleted]",
                    createdAt: diff.createdAt,
                    createdByEmail: (diff.createdByEmail?.includes('@') ? diff.createdByEmail.split('@')[0] : diff.createdByEmail) ?? "Unknown",
                } as DiffViewData);
            }) ?? [];

            // this.#buildData(this.inventoryStatistics);
        } catch (error) {
            this.toastService.error(new CatchError(error).message, 'Error');
        } finally {
            this.loading = false;
        }
    }

    cancel() {
        this.matDialog.close(undefined);
    }

}
