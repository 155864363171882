import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AssetOutput } from 'projects/shared/src/lib/graphql/output/assetOutput';
import { TableColumnOutput } from 'projects/shared/src/lib/graphql/output/tableColumnOutput';
import { AgGridFilterModel, ColDefExt, GatFilterOption } from '../../../../models/agGrid';
import {
    CellContextMenuEvent,
    ColDef,
    ColumnMovedEvent,
    ColumnState,
    FilterChangedEvent,
    GridApi,
    GridReadyEvent,
    IDatasource,
    IGetRowsParams,
    IRowNode,
    ISimpleFilterModel,
    ITooltipParams,
    RowClassParams,
    SelectionChangedEvent,
    ValueGetterParams,
} from 'ag-grid-community';
import { Subscription, firstValueFrom, Subject, debounceTime, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/desktop/src/environments/environment';
import { SelectionService } from '../../../../services/selection.service';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import {
    PropertiesTableFormatQueryArgs,
    PropertiesTableFormatQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/property';
import {
    FULL_FRAGMENT_TABLE_COLUMN
} from 'projects/shared/src/lib/graphql/fragments/fullFragmentTableColumn';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { AssetsQueryArgs, AssetsQueryRoot } from 'projects/shared/src/lib/graphql/crud/asset';
import { FULL_FRAGMENT_ASSET } from 'projects/shared/src/lib/graphql/fragments/fullFragmentAsset';
import { MatDialog } from '@angular/material/dialog';
import {
    NewPlanDialogComponent,
    NewPlanDialogData,
    NewPlanDialogResult,
} from '../../../../component-dialogs/new-plan-dialog/new-plan-dialog.component';
import {
    DeletePlanAssetsMutationArgs, DeletePlanAssetsMutationRoot, PlanQueryArgs, PlanQueryRoot,
    PlansQueryArgs,
    PlansQueryRoot
} from 'projects/shared/src/lib/graphql/crud/plan';
import { FULL_FRAGMENT_PLAN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlan';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import {
    ShowAssetHistoryDialogComponent,
    ShowAssetHistoryDialogData,
} from '../../../../component-dialogs/show-asset-history-dialog/show-asset-history-dialog.component';
import {
    ExportTenantAssetHistoryQueryArgs,
    ExportTenantAssetHistoryQueryRoot,
    ExportTenantAssetTableQueryArgs,
    ExportTenantAssetTableQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/dataExport';
import { FileService } from '../../../../services/file.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import {
    DESKTOP_LOCAL_STORAGE,
    DesktopLocalStorageAssetsTableColumnOrder,
} from '../../../../common/localStorage';
import {
    BookRealtimeActionDialogComponent,
    BookRealtimeActionDialogData,
} from '../../../../component-dialogs/book-realtime-action-dialog/book-realtime-action-dialog.component';
import {
    EditMultipleAssetsDialogComponent,
    EditMultipleAssetsDialogData,
} from '../../../../component-dialogs/edit-multiple-assets-dialog/edit-multiple-assets-dialog.component';
import {
    LocalEventData_PlanAssetDeleted,
    LocalEventService,
    LocalEventType
} from '../../../../services/local-event.service';
import { ActivityService } from '../../../../services/activity.service';
import { DesktopToastService } from '../../../../services/desktop-toast.service';
import {
    SetAssetMissingDialogComponent,
    SetAssetMissingDialogData,
    SetAssetMissingDialogResult,
} from '../../../../component-dialogs/set-asset-missing-dialog/set-asset-missing-dialog.component';
import { SubscriptionService } from '../../../../serices/subscription.service';
import {
    AssetMissingSubNotification
} from 'projects/shared/src/lib/graphql/subNotifications/assetMissingSubNotification';
import { RestService } from '../../../../services/rest.service';
import { AppModule } from '../../../../app.module';
import { NotificationService } from '../../../../services/notificationService/notification.service';
import { RemoteEventType } from '../../../../services/remote-event.service';
import {
    ReportDefectDialogComponent,
    ReportDefectDialogData,
} from '../../../../component-dialogs/report-defect-dialog/report-defect-dialog.component';
import {
    ViewDefectsDialogComponent,
    ViewDefectsDialogData,
} from '../../../../component-dialogs/view-defects-dialog/view-defects-dialog.component';
import {
    localEventHandling,
    createSnapshotDistinctIds,
    onOpenedContextMenuAssetDefects,
    onOpenedContextMenuAssetReminders,
    remoteEventHandling,
} from './assets-table.common';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';
import {
    ShowQrcodeDialogComponent,
    ShowQrcodeDialogData,
} from '../../../../component-dialogs/show-qrcode-dialog/show-qrcode-dialog.component';
import { ColumnOrderInput } from 'projects/shared/src/lib/graphql/input/columnOrderInput';
import {
    InventoryCreateDialogComponent,
    InventoryCreateDialogData,
} from 'projects/desktop/src/app/component-dialogs/inventory-create-dialog/inventory-create-dialog.component';
import { InventoryOutput } from 'projects/shared/src/lib/graphql/output/inventoryOutput';
import {
    buildColumnDefs,
} from './assets-table.column-related';
import {
    AgSidebarComponent
} from 'projects/desktop/src/app/component-helpers/ag/ag-sidebar/ag-sidebar.component';
import {
    ReturnToCustomerCreateDialogComponent,
    ReturnToCustomerCreateDialogData,
    ReturnToCustomerCreateDialogResult,
} from 'projects/desktop/src/app/component-dialogs/return-to-customer-create-dialog/return-to-customer-create-dialog.component';
import { Router } from '@angular/router';
import { PlanAssetsQueryRoot } from "../../../../../../../shared/src/lib/graphql/crud/planAsset";
import {
    FULL_FRAGMENT_PLAN_STEP
} from "../../../../../../../shared/src/lib/graphql/fragments/fullFragmentPlanStep";
import { ConfirmDialogComponent } from 'projects/shared/src/lib/components/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogData
} from "../../../../../../../shared/src/lib/components/confirm-dialog/confirm-dialog.component";
import { ViewAssetRemindersDialogComponent, ViewAssetRemindersDialogData } from 'projects/desktop/src/app/component-dialogs/view-asset-reminders-dialog/view-asset-reminders-dialog.component';

const PlanQuery = gql`
  ${FULL_FRAGMENT_PLAN}
  query Plan($id: String!) {
    plan(id: $id) {
      ...FullFragmentPlan
    }
  }
`;

const PlanAssetsQuery = gql`
  query PlanAssets($planId: String!) {
    planAssets(planId: $planId) {
      id
      planId
      tenantAssetId
    }
  }
`;

const PlanStepsQuery = gql`
  ${FULL_FRAGMENT_PLAN_STEP}
  query PlanSteps($planId: String!) {
    planSteps(planId: $planId) {
      ...FullFragmentPlanStep
    }
  }
`;

class AdditionalFilters {
    get defect() {
        return this.#defect;
    }

    #defect: 'show-all' | 'show-functional-only' | 'show-defect-only' = 'show-all';

    get missing() {
        return this.#missing;
    }

    #missing: 'show-all' | 'show-available-only' | 'show-missing-only' = 'show-all';

    get on(): boolean {
        return this.#defect !== 'show-all' || this.#missing !== 'show-all';
    }

    setDefectFilter(filter: 'show-all' | 'show-functional-only' | 'show-defect-only'): void {
        this.#defect = filter;

        const filterModel = this.context.gridApi.getFilterModel();

        const defectColumnName = 'defectState';
        filterModel[defectColumnName] = undefined;
        switch (filter) {
            case 'show-all':
                // Do nothing. The filter for the column was already reset.
                break;

            case 'show-defect-only':
                filterModel[defectColumnName] = {
                    filterType: 'number',
                    type: 'greaterThan',
                    filter: 1,
                };
                break;

            case 'show-functional-only':
                filterModel[defectColumnName] = {
                    filterType: 'number',
                    type: 'equals',
                    filter: 1,
                };
                break;

            default:
                break;
        }

        // Apply filter model.
        this.context.gridApi.setFilterModel(filterModel);
    }

    setMissingFilter(filter: 'show-all' | 'show-available-only' | 'show-missing-only'): void {
        this.#missing = filter;
        const missingPropertyName = this.context.specialTableColumn_Missing?.field;
        if (!missingPropertyName) {
            return;
        }

        const filterModel = this.context.gridApi.getFilterModel();

        filterModel[missingPropertyName] = undefined;
        switch (filter) {
            case 'show-all':
                // Do nothing. The filter for the column was already reset.
                break;

            case 'show-missing-only':
                filterModel[missingPropertyName] = {
                    filterType: 'text',
                    type: 'equals',
                    filter: 'true',
                };
                break;

            case 'show-available-only':
                filterModel[missingPropertyName] = {
                    filterType: 'text',
                    type: 'equals',
                    filter: 'false',
                };
                break;

            default:
                break;
        }

        // Apply filter model.
        this.context.gridApi.setFilterModel(filterModel);
    }

    reset() {
        this.#defect = 'show-all';
        this.#missing = 'show-all';
    }

    constructor(public context: AssetsTableComponent) {
    }
}

const CURRENT_AND_FUTURE_PLANS_QUERY = gql`
  ${FULL_FRAGMENT_PLAN}
  query Plans($pastIncluded: Boolean!, $currentIncluded: Boolean!, $futureIncluded: Boolean!) {
    plans(
      pastIncluded: $pastIncluded
      currentIncluded: $currentIncluded
      futureIncluded: $futureIncluded
    ) {
      ...FullFragmentPlan
      planAssets {
        id
        tenantAssetId
      }
    }
  }
`;

@Component({
    selector: 'app-assets-table',
    templateUrl: './assets-table.component.html',
    styleUrls: ['./assets-table.component.scss'],
    //encapsulation: ViewEncapsulation.None,
})
export class AssetsTableComponent implements OnInit, OnDestroy {
    // #region In/Out

    //@Input() isPoppedOut: boolean = false;

    // #endregion In/Out

    // #region Public Properties

    @ViewChild('contextMenu', { read: MatMenu, static: false }) contextMenu: MatMenu | undefined;
    @ViewChild('divContextMenuTrigger', { read: MatMenuTrigger, static: false }) contextMenuTrigger:
        | MatMenuTrigger
        | undefined;

    @ViewChild('agSidebar') agSidebar: AgSidebarComponent | undefined;

    generalSearchValue: string = '';
    private searchSubject: Subject<string> = new Subject();

    contextMenuPosition = { x: '0px', y: '0px' };

    assets: AssetOutput[] = [];
    private _assetNameLookup = new Map<string, string>();

    tableColumns: TableColumnOutput[] = [];
    isRebuildingColumnDefsRequired = true;

    columnDefs: ColDefExt[] = [
        // { field: 'data', headerName: 'Loading' },
    ];

    defaultColDef: ColDef = {
        //flex: 1,
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        floatingFilter: true,
        filterParams: {
            buttons: ['clear', 'apply'],
        },
    };

    selectedRows: any[] = [];

    get hasSelectedRows() {
        return this.selectedRows.length > 0;
    }

    get hasPlan() {
        return this.selectedRows.some((x) => x.currentPlanName);
    }

    selectedNodes: IRowNode<any>[] = [];
    canClearFilter = false;

    plans: PlanOutput[] = [];

    gridOptions = {
        context: this,
    };

    rowClassRules = {
        //ag-row-selected
        'custom-row-selected': function (params: RowClassParams): boolean {
            const context = params.context as AssetsTableComponent;
            return context._highlightedRowNodes.has(params.node);
        },
        'custom-row-warning': function (params: RowClassParams): boolean {
            if (!params.data) {
                return false;
            }

            const isDefectOrDefect100 =
                params.data['defectState'] === 3 || params.data['defectState'] === 2;

            // Only return true here if the asset is NOT also missing (missing has priority).
            const context = params.context as AssetsTableComponent;
            const missingPropName = context.specialTableColumn_Missing?.field;
            const isMissing = missingPropName && params.data[missingPropName] === true;

            return isDefectOrDefect100 && !isMissing;
        },
        'custom-row-alert': function (params: RowClassParams): boolean {
            if (!params.data) {
                return false;
            }

            const context = params.context as AssetsTableComponent;
            const missingPropName = context.specialTableColumn_Missing?.field;
            if (!missingPropName) {
                return false;
            }

            return params.data[missingPropName] === true;
        },
    };

    additionalFilters = new AdditionalFilters(this);

    onOpenedContextMenuAssetDefects = onOpenedContextMenuAssetDefects;
    onOpenedContextMenuAssetReminders = onOpenedContextMenuAssetReminders;
    onOpenMenuInventory = createSnapshotDistinctIds;
    onOpenMenuReturnToCustomer = createSnapshotDistinctIds;
    assetHasDefectRecords = new Map<string, boolean>();
    assetHasReminderRecords = new Map<string, boolean>();
    snapshotDistinctColumnValues = new Map<string, string[]>();
    headerFieldsNotEditable: string[] = [];
    headerFieldsTenantLevel: string[] = [];
    filterInventoryId: string | undefined;
    hiddenTableColumns: TableColumnOutput[] = [];
    // #endregion Public Properties

    // #region Private Properties

    private _assetsQuery: QueryRef<AssetsQueryRoot> | undefined;
    private _assetsSubscriptions: Subscription | undefined;
    private _specialTableColumn_AssetId: TableColumnOutput | undefined;
    private _specialTableColumn_Id: TableColumnOutput | undefined;
    specialTableColumn_Missing: TableColumnOutput | undefined;
    specialTableColumn_MissingComment: TableColumnOutput | undefined;
    gridApi!: GridApi;
    #gridDataSource: IDatasource | undefined;
    private _resizeWindowSubscription: Subscription | undefined;

    private _highlightedRowNodes: Set<IRowNode> = new Set();
    #localEventSubscription: Subscription | undefined;
    #assetMissingSubscription: Subscription | undefined;
    #currentAndFuturePlansQuery: QueryRef<PlansQueryRoot> | undefined;
    #currentAndFuturePlansSubscription: Subscription | undefined;
    #localEventHandledSubscription: Subscription | undefined;
    #notificationLocalSubscription: Subscription | undefined;
    #notificationRemoteSubscription: Subscription | undefined;

    // #endregion Private Properties

    // #region Init

    constructor(
        public http: HttpClient,
        public selectionService: SelectionService,
        public apollo: Apollo,
        private _matDialog: MatDialog,
        private _fileService: FileService,
        private _toastService: DesktopToastService,
        private _clipboard: Clipboard,
        public localeService: LocaleService,
        @Inject(MAT_DATE_LOCALE) public locale: string,
        public datePipe: DatePipe,
        private _localEventService: LocalEventService,
        private _activityService: ActivityService,
        public subscriptionService: SubscriptionService,
        public restService: RestService,
        private _notificationService: NotificationService,
        private _assetService: AssetService,
        private toastService: DesktopToastService,
        private router: Router
    ) {
        this.searchSubject.pipe(
            debounceTime(200)
        ).subscribe(() => {
            this.onDebouncedSearchChange();
        });
    }

    ngOnInit(): void {
        this._loadCurrentAndFuturePlans();
        this.loadColumnInfos();
        this._loadAssets();

        this._notificationService.handleLocalEvents([
            LocalEventType.PlanAssetDeleted,
            LocalEventType.PlanAssetsAdded,
            LocalEventType.AssetMissing,
            LocalEventType.AssetDefect,
            LocalEventType.BookingRealtime,
            LocalEventType.BookingPlanned,
            LocalEventType.InventoryAssetsCreated,
            LocalEventType.InventoryAssetsDeleted,
            LocalEventType.Inventory,
            LocalEventType.Plan,
        ]);
        this._notificationService.handleRemoteEvents([
            RemoteEventType.PlanAssetsDeleted,
            RemoteEventType.PlanAssetsAdded,
            RemoteEventType.AssetMissing,
            RemoteEventType.AssetDefect,
            RemoteEventType.BookingRealtime,
            RemoteEventType.BookingPlanned,
            RemoteEventType.InventoryAssetsBooked,
            RemoteEventType.InventoryAssetsCreated,
            RemoteEventType.InventoryAssetsDeleted,
            RemoteEventType.Inventory,
            RemoteEventType.Plan,
        ]);
        this.#notificationLocalSubscription = this._notificationService.localEventHandled.subscribe(
            localEventHandling.bind(this)
        );
        this.#notificationRemoteSubscription = this._notificationService.remoteEventHandled.subscribe(
            remoteEventHandling.bind(this)
        );

        this.#localEventSubscription = this._localEventService.newEvent.subscribe(async (event) => {
            if (event.type === LocalEventType.FilterPlanAssetsInAssetsTable) {
                const propName = this._specialTableColumn_Id?.field;
                if (!propName) {
                    return;
                }

                const tenantAssetIds = event.data as string[];
                const filterModel = this.gridApi.getFilterModel();
                const selectedRowsFilterModel: ISimpleFilterModel | any = {
                    filterType: 'text',
                    type: 'in',
                    filter: tenantAssetIds.join(','),
                };
                filterModel[propName] = selectedRowsFilterModel;
                this.gridApi.setFilterModel(filterModel);
            }
        });
    }

    ngOnDestroy(): void {
        this._assetsSubscriptions?.unsubscribe();
        this._resizeWindowSubscription?.unsubscribe();
        this.#localEventSubscription?.unsubscribe();
        this.#assetMissingSubscription?.unsubscribe();
        this.#currentAndFuturePlansSubscription?.unsubscribe();
        this.#localEventHandledSubscription?.unsubscribe();

        this._notificationService.unhandleLocalEvents([
            LocalEventType.PlanAssetDeleted,
            LocalEventType.PlanAssetsAdded,
            LocalEventType.AssetMissing,
            LocalEventType.AssetDefect,
            LocalEventType.BookingRealtime,
            LocalEventType.BookingPlanned,
            LocalEventType.InventoryAssetsCreated,
            LocalEventType.InventoryAssetsDeleted,
            LocalEventType.Inventory,
            LocalEventType.Plan,
        ]);
        this._notificationService.unhandleRemoteEvents([
            RemoteEventType.PlanAssetsDeleted,
            RemoteEventType.PlanAssetsAdded,
            RemoteEventType.AssetMissing,
            RemoteEventType.AssetDefect,
            RemoteEventType.BookingRealtime,
            RemoteEventType.BookingPlanned,
            RemoteEventType.InventoryAssetsBooked,
            RemoteEventType.InventoryAssetsCreated,
            RemoteEventType.InventoryAssetsDeleted,
            RemoteEventType.Inventory,
            RemoteEventType.Plan,
        ]);
        this.#notificationLocalSubscription?.unsubscribe();
        this.#notificationRemoteSubscription?.unsubscribe();
    }

    // #endregion Init

    // #region Public Methods

    reloadTable() {
        this.clearAllFilters();
        this.gridApi.setGridOption('datasource', this.#gridDataSource);
        this.generalSearchValue = '';
    }

    setFilterModel(filterModel: { [key: string]: any }) {
        this.gridApi.setFilterModel(filterModel);
    }

    onDragStart(event: DragEvent) {
        console.log(event);
    }

    onTableMouseDown(event: MouseEvent) {
        if (event.shiftKey) {
            // This is to prevent the browsers text selection when the user selects multiple rows with SHIFT.
            event.preventDefault();
        }
    }

    onTableRightClick(event: MouseEvent) {
        // This is necessary to prevent the browser from opening its context menu.
        // However, allow the browser context menu with the Ctrl key pressed.
        if (event.ctrlKey) {
            return;
        }
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        if (this.contextMenuTrigger?.menuOpen) {
            this.contextMenuTrigger.closeMenu();
        }
    }

    onCellContextMenu(contextEvent: CellContextMenuEvent) {
        if (!this.contextMenuTrigger || (contextEvent.event as MouseEvent).ctrlKey) {
            return;
        }

        // Highlight the cell so that the user has a visual feedback about
        // "where" he performed the right click.
        this._highlightedRowNodes.add(contextEvent.node);
        contextEvent.api.redrawRows({ rowNodes: [contextEvent.node] });

        this.contextMenuTrigger.menuData = {
            context: {
                assetHasDefectRecords: this.assetHasDefectRecords,
                assetHasReminderRecords: this.assetHasReminderRecords,
                columnValue: contextEvent.value,
                columnPropertyId: contextEvent.colDef.field,
                columnPropertyHeaderName: contextEvent.colDef.headerName,
                columnPropertyIsNotEditable: this.headerFieldsNotEditable.includes(
                    contextEvent.colDef.field ?? 'na'
                ),
                data: contextEvent.data,
                selectedNode: contextEvent.node,
                isMultiEditVisible: this.selectedNodes.map((x) => x.id).includes(contextEvent.node.id),
                futurePlans: this.#determineFuturePlansForAsset(contextEvent.data),
            },
        };
        this.contextMenuTrigger.openMenu();

        // Now that the overlay will be "active", handle a contextmenu (right click) on it.
        const overlayElement = document.body.getElementsByClassName('cdk-overlay-container')[0];
        overlayElement.addEventListener('contextmenu', (event: any) => {
            this.contextMenuTrigger?.closeMenu();
            (event as MouseEvent).preventDefault();
        });
    }

    onContextMenuClosed() {
        // It might be that we will have a contextmenu handler on the overlay. Remove it.
        const overlayElement = document.body.getElementsByClassName('cdk-overlay-container')[0];
        overlayElement.removeAllListeners?.('contextmenu');

        // It might be that we have some highlighted rows. Remove the highlights.
        const rowNodes = Array.from(this._highlightedRowNodes);
        this._highlightedRowNodes.clear();
        this.gridApi.redrawRows({ rowNodes });
    }

    copyToClipboard(tenantAssetId: string) {
        this._clipboard.copy(tenantAssetId);
    }

    showQRCode(assets: any[]) {
        const data: ShowQrcodeDialogData = {
            items: assets.map((x) => x.id),
        };

        this._matDialog.open(ShowQrcodeDialogComponent, {
            data,
        });
    }

    showHistory(tenantAssetId: string) {
        const data: ShowAssetHistoryDialogData = {
            tenantAssetId,
        };

        const dialog = this._matDialog.open(ShowAssetHistoryDialogComponent, {
            data,
        });
    }

    reportDefect(tenantAssetId: string, tryToVerify: boolean) {
        const data: ReportDefectDialogData = {
            assetId: tenantAssetId,
            tryToVerify,
        };

        const dialog = this._matDialog.open(ReportDefectDialogComponent, {
            autoFocus: false,
            minWidth: 680,
            maxWidth: 800,
            data,
        });

        dialog.afterClosed().subscribe((result) => {
            // Do nothing.
        });
    }

    viewDefects(tenantAssetId: string) {
        const data: ViewDefectsDialogData = {
            assetId: tenantAssetId,
        };

        const dialog = this._matDialog.open(ViewDefectsDialogComponent, {
            autoFocus: false,
            minWidth: 680,
            maxWidth: 800,
            data,
        });
    }

    createReminder(tenantAssetId: string) {
        // const data = {
        //     assetId: tenantAssetId,
        // };

        // const dialog = this._matDialog.open(ReportDefectDialogComponent, {
        //     autoFocus: false,
        //     minWidth: 680,
        //     maxWidth: 800,
        //     data,
        // });

        // dialog.afterClosed().subscribe((result) => {
        //     // Do nothing.
        // });
    }

    viewReminders(tenantAssetId: string) {
        const data: ViewAssetRemindersDialogData = {
            assetId: tenantAssetId,
        };

        const dialog = this._matDialog.open(ViewAssetRemindersDialogComponent, {
            autoFocus: false,
            minWidth: 800,
            maxWidth: 1000,
            data,
        });
    }

    async exportHistoryToExcel(tenantAssetId: string) {
        const variables: ExportTenantAssetHistoryQueryArgs = {
            tenantAssetId,
        };

        try {
            const result = await firstValueFrom(
                this.apollo.query<ExportTenantAssetHistoryQueryRoot>({
                    query: gql`
            query ExportTenantAssetHistory($tenantAssetId: String!) {
              exportTenantAssetHistory(tenantAssetId: $tenantAssetId)
            }
          `,
                    variables,
                    fetchPolicy: 'network-only',
                })
            );

            await this._fileService.download(
                this.selectionService.selectedTenant?.id,
                result.data.exportTenantAssetHistory
            );
        } catch (error) {
            const message = new CatchError(error).message;
            this._toastService.error(message, 'ERROR');
        }
    }

    async goToCurrentPlanByName(currentPlanName: string, mode: 'above' | 'zen') {
        // Load current plans and search by name.
        try {
            const plan = await this.getPlanByName(currentPlanName);
            if (!plan) {
                this._toastService.error('Could not find current plan', 'Error');
            }

            this.selectionService.selectedPlan = plan;

            if (mode === 'zen') {
                this.selectionService.assetsView.zenMode('plan-builder');
            } else {
                this.selectionService.assetsView.showPlanBuilder = true;
            }
        } catch (error) {
            this._toastService.error(new CatchError(error).message, 'Error');
        }
    }

    private async getPlanByName(currentPlanName: string) {
        const variables: PlansQueryArgs = {
            currentIncluded: true,
            futureIncluded: false,
            pastIncluded: false,
        };

        const result = await firstValueFrom(
            this.apollo.query<PlansQueryRoot>({
                query: gql`
            ${FULL_FRAGMENT_PLAN}
            query Plans(
              $pastIncluded: Boolean!
              $currentIncluded: Boolean!
              $futureIncluded: Boolean!
              $pastUntil: DateTime
              $futureUntil: DateTime
            ) {
              plans(
                pastIncluded: $pastIncluded
                currentIncluded: $currentIncluded
                futureIncluded: $futureIncluded
                pastUntil: $pastUntil
                futureUntil: $futureUntil
              ) {
                ...FullFragmentPlan
              }
            }
          `,
                variables,
                fetchPolicy: 'network-only',
            })
        );

        const plan = result.data.plans.find(
            (x) => x.name.toLowerCase() === currentPlanName.toLowerCase()
        );
        return plan;
    }

    goToFuturePlan(plan: any, mode: 'above' | 'zen'): void {
        this.selectionService.selectedPlan = plan;

        if (mode === 'zen') {
            this.selectionService.assetsView.zenMode('plan-builder');
        } else {
            this.selectionService.assetsView.showPlanBuilder = true;
        }
    }

    async exportAssetsToExcel(onlySelected: boolean) {
        if (onlySelected && !this.hasSelectedRows) {
            return;
        }

        let fileId: string | undefined;

        const filterModel = this.gridApi.getFilterModel();
        const isExportAll = Object.keys(filterModel).length === 0;

        let details = '';
        if (onlySelected) {
            details = 'Selected';
        } else if (!onlySelected && isExportAll) {
            details = 'All';
        } else {
            details = 'Filtered';
        }

        this._activityService.open(
            `Export ${details} Assets`,
            'Generating Excel ...',
            async () => {
                if (onlySelected) {
                    // If only the selected rows should be exported, we have to adapt
                    // the filterModel for the backend as it currently MIGHT NOT
                    // include a filtering of the selected rows.

                    const propName = this._specialTableColumn_Id?.field;
                    if (!propName) {
                        return;
                    }

                    const selectedRowsFilterModel: ISimpleFilterModel | any = {
                        filterType: 'text',
                        type: 'in',
                        filter: this.selectedRows.map((x) => x[propName]).join(','),
                    };

                    filterModel[propName] = selectedRowsFilterModel;
                }

                const sortModel = this.gridApi
                    ?.getColumnState()
                    .filter((s) => s.sort !== null)
                    .map((x) => {
                        return { colId: x.colId, sort: x.sort };
                    });

                const assetQuery = {
                    filterModel: Object.keys(filterModel).length > 0 ? filterModel : null,
                    sortModel: (sortModel?.length ?? 0) > 0 ? sortModel : [],
                };

                let columnOrder: ColumnOrderInput[] = [];
                const orderString = localStorage.getItem(DESKTOP_LOCAL_STORAGE.ASSETS_TABLE_COLUMN_ORDER);
                if (orderString) {
                    columnOrder = JSON.parse(orderString) as Array<ColumnOrderInput>;
                }

                const variables: ExportTenantAssetTableQueryArgs = {
                    assetQueryJson: JSON.stringify(assetQuery),
                    columnOrder,
                };

                const result = await firstValueFrom(
                    this.apollo.query<ExportTenantAssetTableQueryRoot>({
                        query: gql`
              query ExportTenantAssetTable(
                $assetQueryJson: String!
                $columnOrder: [ColumnOrderInput!]!
              ) {
                exportTenantAssetTable(assetQueryJson: $assetQueryJson, columnOrder: $columnOrder)
              }
            `,
                        variables,
                        fetchPolicy: 'network-only',
                    })
                );

                fileId = result.data.exportTenantAssetTable;
            },
            async () => {
                try {
                    await this._fileService.download(this.selectionService.selectedTenant?.id, fileId);
                } catch (error) {
                    this._toastService.error('Could not download generated Excel file.', 'Error');
                }
            }
        );
    }

    async setAssetsMissingState(toMissing: boolean) {
        if (!this.hasSelectedRows) {
            return;
        }

        const data: SetAssetMissingDialogData = {
            setAsMissing: toMissing,
            assets: this.selectedRows,
            missingPropertyName: this.specialTableColumn_Missing?.field ?? 'na',
        };

        const dialog = this._matDialog.open(SetAssetMissingDialogComponent, {
            autoFocus: false,
            data,
            minWidth: 480,
        });

        dialog.afterClosed().subscribe((assetMissings: SetAssetMissingDialogResult) => {
            (document.activeElement as HTMLElement)?.blur();

            if (typeof assetMissings === 'undefined') {
                return;
            }

            const info: AssetMissingSubNotification = {
                filterSessionId: AppModule.sessionId,
                data: [],
            };

            for (const assetMissing of assetMissings) {
                info.data?.push({
                    assetId: assetMissing.id,
                    action: assetMissing.missing ? 'set' : 'reset',
                    planStepAction: null,
                    missingComment: assetMissing.missingComment,
                    userOid: this.selectionService.myUser?.oid ?? null,
                });
            }

            this._localEventService.emitNewEvent(LocalEventType.AssetMissing, info);
        });
    }

    onClickNewPlan() {
        const data: NewPlanDialogData = {
            assets: this.selectedRows,
        };

        const dialog = this._matDialog.open(NewPlanDialogComponent, {
            data,
            autoFocus: true,
            maxWidth: '600px',
            width: '600px',
        });

        dialog.afterClosed().subscribe((result: NewPlanDialogResult | undefined) => {
            (document.activeElement as HTMLElement)?.blur();
        });
    }

    onClickBookAction() {
        const data: BookRealtimeActionDialogData = {
            assets: this.selectedRows,
        };

        const dialog = this._matDialog.open(BookRealtimeActionDialogComponent, {
            data,
            autoFocus: false,
            minWidth: 640,
            disableClose: true,
        });

        dialog.afterClosed().subscribe(() => {
            (document.activeElement as HTMLElement)?.blur();
        });
    }

    editAssets(
        columnPropertyId: string,
        columnPropertyHeaderName: string,
        clickedNode: IRowNode | undefined = undefined
    ) {
        const data: EditMultipleAssetsDialogData = {
            columnPropertyId,
            columnPropertyHeaderName,
            assets: [],
        };

        if (typeof clickedNode === 'undefined') {
            data.assets = this.selectedNodes.map((x) => x.data);
        } else {
            data.assets = [clickedNode.data];
        }

        const dialog = this._matDialog.open(EditMultipleAssetsDialogComponent, {
            autoFocus: false,
            minWidth: 640,
            data,
        });

        dialog.afterClosed().subscribe((reload: boolean) => {
            if (!this.#gridDataSource || !reload) {
                return;
            }
            this.gridApi.setGridOption('datasource', this.#gridDataSource);
        });
    }

    assetIdValueGetter(params: ValueGetterParams): string {
        const columnName = params.colDef.field; // is uuid

        let data: any = {};
        Object.assign(data, params.data);

        const cellValue: string = data[columnName ?? 'na'];
        return this._getAssetNameById(cellValue) ?? '';
    }

    assetIdTooltipValueGetter(params: ITooltipParams): string {
        const columnName = (params.colDef as ColDef).field; // is uuid

        let data: any = {};
        Object.assign(data, params.data);

        const cellValue: string = data[columnName ?? 'na'];
        return this._getAssetNameById(cellValue) ?? '';
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;

        //if (this.isPoppedOut) {
        params.api.setGridOption('pagination', false);
        //}
        const url = `${environment.apiBaseUrl}/api/assets/${this.selectionService.selectedTenant?.id ?? 'na'
            }`;

        const body = {
            pageModel: {
                startRow: 0,
                endRow: 25,
            },
            inventoryId: this.filterInventoryId,
        };

        const headers = {
            'Gat-Tenant-Id': this.selectionService.selectedTenant?.id ?? 'na',
            'Gat-Date': new Date().toISOString(),
            'Gat-Zone': DateTime.local().zone.name,
            'Gat-Session-Id': AppModule.sessionId,
        };

        this.http
            .post(url, body, {
                headers,
            })
            .subscribe((data) => {
                //console.log(data);
                const dataSource: IDatasource = {
                    rowCount: undefined,
                    getRows: (params: IGetRowsParams) => {
                        console.log('asking for ' + params.startRow + ' to ' + params.endRow);
                        //console.log(params.filterModel);
                        //console.log(params.filterModel);

                        interface IFilterModel {
                            [propName: string]: {
                                filterType: string;
                                type?: string; // only for SINGLE filters (no OR or AND)
                                filter?: string; // only for SINGLE filters (no OR or AND)
                            };
                        }

                        // Special treatment for column AssetId and all option columns
                        const gatFilterOptions: GatFilterOption[] = [];
                        if (this._specialTableColumn_AssetId?.field) {
                            // Handle column AssetId
                            gatFilterOptions.push({
                                filterPropName: this._specialTableColumn_AssetId?.field,
                                items: this.assets,
                                itemSearchPropName: 'name',
                                itemResultPropName: 'id',
                            });
                        }

                        const transformedFilterModel = new AgGridFilterModel(
                            params.filterModel,
                            gatFilterOptions
                        ).transformed;

                        const body: any = {
                            pageModel: {
                                startRow: params.startRow,
                                endRow: params.endRow,
                            },
                            sortModel: params.sortModel,
                            filterModel: transformedFilterModel,
                            searchString: this.generalSearchValue,
                            inventoryId: this.filterInventoryId,
                        }
                        //console.log(params);
                        firstValueFrom(
                            this.http.post<any[]>(
                                url,
                                body,
                                { headers }
                            )
                        ).then((dataNext) => {
                            // if (this.generalSearchValue != '') {
                            //   dataNext = dataNext.filter((x) => {
                            //     return Object.values(x).some((y: any) => {
                            //       if (y === null) {
                            //         return false;
                            //       }
                            //       return y.toString().toLowerCase().includes(this.generalSearchValue.toLowerCase());
                            //     });
                            //   })
                            // }

                            let lastRow: number | undefined;
                            //console.log(dataNext);
                            const pageSize = params.endRow - params.startRow;
                            if (dataNext.length < pageSize) {
                                // Has reached the "end of data" (we have not received a full page).
                                // Set lastRow
                                lastRow = params.startRow + dataNext.length;
                            }

                            params.successCallback(dataNext, lastRow);
                        });
                    },
                };
                this.#gridDataSource = dataSource;
                params.api.setGridOption('datasource', dataSource);
            });
    }

    get getRowId() {
        return (params: any): string => {
            return params.data[this._specialTableColumn_Id?.field ?? 'na'];
        };
    }

    onSelectionChanged(event: SelectionChangedEvent) {
        this.selectedRows = event.api.getSelectedRows();
        this.selectedNodes = event.api.getSelectedNodes();

        this.selectionService.selectedAssets = this.selectedRows;
        this.selectionService.selectedNodesInAssetsTable = this.selectedNodes;
    }

    onFilterChanged(event: FilterChangedEvent) {
        const model = event.api.getFilterModel();
        const hasNoFilter = Object.keys(model).length === 0 && model.constructor === Object;
        this.canClearFilter = !hasNoFilter;
    }

    deselectSelectedRows() {
        this.gridApi.deselectAll(); // Will trigger "onSelectionChange"
    }

    clearAllFilters() {
        this.additionalFilters.reset();
        this.gridApi.setFilterModel(null);
    }

    filterSelectedRows() {
        const propName = this._specialTableColumn_Id?.field;
        if (!propName) {
            return;
        }

        const filterModel: ISimpleFilterModel | any = {
            filterType: 'text',
            type: 'in',
            filter: this.selectedRows.map((x) => x[propName]).join(','),
        };

        interface IModel {
            [name: string]: any;
        }

        const model: IModel = {};
        model[propName] = filterModel;

        this.gridApi.setFilterModel(model);
    }

    onColumnMoved(event: ColumnMovedEvent) {
        // Store the column order to LOCAL STORAGE
        this.#persistColumnState(DESKTOP_LOCAL_STORAGE.ASSETS_TABLE_COLUMN_ORDER);
        this.agSidebar?.rebuildColumnData();
    }

    onRestoreDefaultColumnOrder() {
        if (!this.#gridDataSource) {
            return;
        }

        const currentColumnStates = this.gridApi?.getColumnState();
        const defaultColumnStates = this.#generateDefaultColumnStates(currentColumnStates ?? []);
        this.gridApi?.applyColumnState({ state: defaultColumnStates, applyOrder: true });

        localStorage.removeItem(DESKTOP_LOCAL_STORAGE.ASSETS_TABLE_COLUMN_ORDER);
    }

    onFilterInventory(inventory: InventoryOutput | undefined) {
        this.filterInventoryId = inventory?.id;

        if (this.#gridDataSource) {
            this.gridApi.setGridOption('datasource', this.#gridDataSource);
        }
    }

    startInventory(
        mode: 'all' | 'selected' | 'filtered',
        assetIds: string[] | undefined = undefined
    ) {
        const data: InventoryCreateDialogData = {
            mode,
            assetIds: [],
        };

        // Set assetIds
        if (mode === 'selected') {
            data.assetIds = this.selectedRows.map((x) => x.id);
        } else if (mode === 'filtered') {
            if (typeof assetIds === 'undefined') {
                return; // Should not happen.
            }

            data.assetIds = assetIds;
        }

        const dialog = this._matDialog.open(InventoryCreateDialogComponent, {
            data,
            autoFocus: true,
            minWidth: 400,
        });
    }

    startReturnToCustomer(mode: 'selected' | 'filtered', assetIds: string[] | undefined = undefined) {
        const data: ReturnToCustomerCreateDialogData = {
            mode,
            assetIds: [],
        };

        // Set assetIds
        if (mode === 'selected') {
            data.assetIds = this.selectedRows.map((x) => x.id);
        } else if (mode === 'filtered') {
            if (typeof assetIds === 'undefined') {
                return; // Should not happen.
            }
            data.assetIds = assetIds;
        }

        const dialog = this._matDialog.open(ReturnToCustomerCreateDialogComponent, {
            data,
            autoFocus: true,
            minWidth: 400,
        });

        dialog.afterClosed().subscribe((rtc: ReturnToCustomerCreateDialogResult | undefined) => {
            if (!rtc) {
                return;
            }

            // Navigate to created RTC.
            this.router.navigateByUrl('/assets/return-to-customer/' + rtc.id);
        });
    }

    // #endregion Public Methods

    // #region Private Methods

    async loadColumnInfos() {
        try {
            const variables: PropertiesTableFormatQueryArgs = {
                tenantId: this.selectionService.selectedTenant?.id ?? 'na',
            };

            const result = await firstValueFrom(
                this.apollo.query<PropertiesTableFormatQueryRoot>({
                    query: gql`
            ${FULL_FRAGMENT_TABLE_COLUMN}
            query PropertiesTableFormat($tenantId: String!) {
              propertiesTableFormat(tenantId: $tenantId) {
                ...FullFragmentTableColumn
              }
            }
          `,
                    variables,
                    fetchPolicy: 'no-cache',
                })
            );

            this.tableColumns = result.data.propertiesTableFormat;

            // this.hiddenTableColumns = this.tableColumns.filter(
            //   (x) =>
            //     HIDDEN_COLUMN_PROPERTIES.includes(x.field) || HIDDEN_COLUMN_NAMES.includes(x.headerName)
            // );
            this._specialTableColumn_AssetId = this.tableColumns.find((x) => x.headerName === 'AssetId');
            this._specialTableColumn_Id = this.tableColumns.find((x) => x.field === 'id');
            //this.specialTableColumn_Defect = this.tableColumns.find((x) => x.headerName === 'Defect');
            this.specialTableColumn_Missing = this.tableColumns.find((x) => x.headerName === 'Missing');
            this.specialTableColumn_MissingComment = this.tableColumns.find(
                (x) => x.headerName === 'Missing Comment'
            );

            this.isRebuildingColumnDefsRequired = true;
            buildColumnDefs.call(this);
        } catch (error) {
            const message = new CatchError(error).message;
            // TODO
            console.log(error);
        }
    }

    private _loadCurrentAndFuturePlans() {
        this.#currentAndFuturePlansSubscription?.unsubscribe();
        const variables: PlansQueryArgs = {
            pastIncluded: false,
            currentIncluded: true,
            futureIncluded: true,
        };

        this.#currentAndFuturePlansQuery = this.apollo.watchQuery<PlansQueryRoot>({
            query: CURRENT_AND_FUTURE_PLANS_QUERY,
            variables,
            fetchPolicy: 'network-only',
        });

        this.#currentAndFuturePlansSubscription =
            this.#currentAndFuturePlansQuery.valueChanges.subscribe({
                next: (result) => {
                    this.plans = result.data.plans.sortBy((x) => x.planStart);
                },
            });
    }

    private _loadAssets() {
        const variables: AssetsQueryArgs = {
            tenantId: this.selectionService.selectedTenant?.id ?? 'na',
        };

        this._assetsQuery = this.apollo.watchQuery<AssetsQueryRoot>({
            query: gql`
        ${FULL_FRAGMENT_ASSET}
        query Assets($tenantId: String!) {
          assets(tenantId: $tenantId) {
            ...FullFragmentAsset
          }
        }
      `,
            variables,
            fetchPolicy: 'cache-first',
        });

        this._assetsSubscriptions = this._assetsQuery.valueChanges.subscribe({
            next: ({ data, loading }) => {
                this.assets = data.assets;

                this.isRebuildingColumnDefsRequired = true;

                if (!loading) {
                    buildColumnDefs.call(this);
                }
            },
            error: (error) => {
                // TODO
                console.log(error);
            },
        });
    }

    private _getAssetNameById(id: string): string | undefined {
        // First check if the value was calculated and cached before.
        if (this._assetNameLookup.has(id)) {
            return this._assetNameLookup.get(id);
        }

        // No previously calculated value found.
        // Check the available assets.
        const asset = this.assets.find((x) => x.id === id);
        if (!asset) {
            return '';
        }

        this._assetNameLookup.set(id, asset.name);
        return asset.name;
    }

    #persistColumnState(localStorageKey: string) {
        if (!this.gridApi) {
            return;
        }

        const columnState = this.gridApi.getColumnState();
        const lsOrder: DesktopLocalStorageAssetsTableColumnOrder = [];

        for (let i = 0; i < columnState.length; i++) {
            lsOrder.push({
                colId: columnState[i].colId,
                index: i,
            });
        }

        localStorage.setItem(localStorageKey, JSON.stringify(lsOrder));
    }

    #generateDefaultColumnStates(columnStates: ColumnState[]): ColumnState[] {
        console.log(columnStates);
        const defaultOrderString = localStorage.getItem(
            DESKTOP_LOCAL_STORAGE.ASSETS_TABLE_DEFAULT_COLUMN_ORDER
        );
        if (!defaultOrderString) {
            return columnStates;
        }

        try {
            const defaultOrder = JSON.parse(defaultOrderString) as Array<{
                colId: string;
                index: number;
            }>;
            console.log(defaultOrder);

            const returnColumnStates: ColumnState[] = [];

            // First, determine the "computed" columns that will not be included in the
            // persisted default column info (e.g. the "Info" column),
            const additionalComputedColumns = columnStates.filter(
                (x) => !defaultOrder.map((x) => x.colId).includes(x.colId)
            );

            // Put these at the front.
            returnColumnStates.push(...additionalComputedColumns);

            // Now, the persisted column information and the "remaining" current columns should be the same number.
            // Just order them according to the order of the persisted info.
            for (const persistedColumn of defaultOrder) {
                const columnState = columnStates.find((x) => x.colId === persistedColumn.colId);
                if (!columnState) {
                    continue; // should not happen
                }
                returnColumnStates.push(columnState);
            }
            console.log(returnColumnStates);
            return returnColumnStates;
        } catch (error) {
            return columnStates;
        }
    }

    async refetchAssetDataAndRefreshTable(
        tenantAssetIds: string[],
        fromCacheOnly: boolean | undefined = false
    ) {
        if (tenantAssetIds.length === 0) {
            return;
        }

        let assetsData: any[] | undefined;

        if (fromCacheOnly) {
            assetsData = this._assetService.getCachedAssets(tenantAssetIds);
        } else {
            // The following should be replaced with the call from the assetService.
            assetsData = await this.restService.fetchAssets(
                this.selectionService.selectedTenant?.id,
                tenantAssetIds
            );
        }

        if (typeof assetsData !== 'undefined') {
            for (const data of assetsData) {
                const rowNode = this.gridApi.getRowNode(data.id);
                // Reset the row data with the same data just to trigger re-rendering.
                rowNode?.setData(data);
            }
        }
    }

    /** Only work on the cached plans. */
    #determineFuturePlansForAsset(data: any): PlanOutput[] {
        const futurePlans: PlanOutput[] = [];

        const variables: PlansQueryArgs = {
            currentIncluded: true,
            futureIncluded: true,
            pastIncluded: false,
        };
        const cachedPlans =
            this.apollo.client.readQuery<PlansQueryRoot>({
                query: CURRENT_AND_FUTURE_PLANS_QUERY,
                variables,
            })?.plans ?? [];

        // Determine the FUTURE plans that INCLUDE the provided asset.
        return cachedPlans
            .filter((x) => new Date(x.planStart).getTime() > Date.now())
            .filter((x) => x.planAssets?.some((y) => y.tenantAssetId === data.id))
            .sortBy((x) => x.planStart);
    }

    // #endregion Private Methods
    onGeneralSearchChange($event: any) {
        this.searchSubject.next('');
    }

    private onDebouncedSearchChange() {
        this.gridApi.setGridOption('datasource', this.#gridDataSource);
    }
    //TODO this is copied from the asset table component, should be refactored
    async removeAssetFromPlan(asset: any, currentPlanName: any) {
        const plan = await this.getPlanByName(currentPlanName);
        if (!plan?.id) {
            return;
        }

        try {
            // Check if the asset is included in any plan step.
            // If so, it must not be deleted from the plan.

            const planQueryVariables: PlanQueryArgs = {
                id: plan.id,
            };
            const result = await firstValueFrom(
                this.apollo.query<PlanQueryRoot>({
                    query: gql`
            query Plan($id: String!) {
              plan(id: $id) {
                id
                planSteps {
                  id
                  name
                  planStepAssets {
                    id
                    tenantAssetId
                  }
                }
              }
            }
          `,
                    variables: planQueryVariables,
                    fetchPolicy: 'no-cache',
                })
            );

            const planSteps = result.data.plan.planSteps!.filter((x) =>
                x.planStepAssets?.map((y) => y.tenantAssetId).includes(asset.id)
            );
            // const planSteps = this.planSteps?.filter(x => x.planStepAssets?.map(y => y.tenantAssetId).includes(tenantAssetId));
            if ((planSteps?.length ?? 0) > 0) {
                this.toastService.error(
                    'Asset cannot be deleted. It is included in the following steps: ' +
                    planSteps?.map((x) => x.name).join(', ')
                );
                return;
            }

            const variables: DeletePlanAssetsMutationArgs = {
                planId: plan?.id ?? 'na',
                assetIds: [asset.id],
            };

            await firstValueFrom(
                this.apollo.mutate<DeletePlanAssetsMutationRoot>({
                    mutation: gql`
            mutation DeletePlanAssets($planId: String!, $assetIds: [String!]!) {
              deletePlanAssets(planId: $planId, assetIds: $assetIds) {
                id
                planAssets {
                  id
                }
              }
            }
          `,
                    variables,
                    fetchPolicy: 'network-only',
                    update: (cache, { data }) => {
                        const cacheQueryResult = cache.readQuery<PlanAssetsQueryRoot>({
                            query: PlanAssetsQuery,
                            variables: { planId: plan?.id },
                        });

                        const cachedArray = cacheQueryResult?.planAssets;
                        if (typeof cachedArray === 'undefined') {
                            return;
                        }

                        const index = cachedArray.findIndex((x) => x.tenantAssetId === asset.id);
                        if (index === -1) {
                            return;
                        }

                        const clonedArray = Array.from(cachedArray);
                        clonedArray.splice(index, 1);

                        cache.writeQuery<PlanAssetsQueryRoot>({
                            query: PlanAssetsQuery,
                            data: {
                                planAssets: clonedArray,
                            },
                            variables: { planId: plan?.id },
                        });

                        const eventData: LocalEventData_PlanAssetDeleted = {
                            planId: plan?.id ?? 'na',
                            tenantAssetId: asset.id,
                        };
                        this._localEventService.emitNewEvent(LocalEventType.PlanAssetDeleted, eventData);
                    },
                })
            );
        } catch (error) {
            const message = new CatchError(error).message;
            this.toastService.error(message, 'Error');
        }

    }

    async onDeleteSelectedAssets() {
        const data: ConfirmDialogData = {
            commentEnabled: true,
            title: 'Are you sure?',
            text: `Do you really want to delete the selected assets?`,
            onConfirm: async (comment) => {
                await this.deleteAssets(comment);
            }
        }

        this._matDialog.open(ConfirmDialogComponent, {
            data
        });
    }

    private async deleteAssets(comment?: string) {

        const assetIds = this.selectedRows.map((x) => x.id);
        const mutation = gql`
    mutation CreateInactiveAsset($data: InactiveAssetInputCreate!) {
      createInactiveAsset(data: $data) {
        id
      }
    }
  `;

        const results = await Promise.allSettled(assetIds.map(tenantAssetId =>
            lastValueFrom(this.apollo.mutate({
                mutation: mutation,
                variables: {
                    data: { tenantAssetId, comment }
                }
            })).then(res => ({ tenantAssetId, res })).catch(error => ({ tenantAssetId, error }))
        ));

        const errors: any[] = [];
        const successfulIds: any[] = [];

        results.forEach(result => {
            if (result.status === 'fulfilled') {
                //@ts-ignore
                const { tenantAssetId, res, error } = result.value;
                if (res && res.data) {
                    successfulIds.push(tenantAssetId);
                } else if (error) {
                    errors.push({ tenantAssetId, error });
                }
            } else {
                //@ts-ignore
                const { tenantAssetId, error } = result.value;
                errors.push({ tenantAssetId, error });
            }
        });

        this.deselectSelectedRows();
        this.gridApi.setGridOption('datasource', this.#gridDataSource);

        if (errors.length > 0) {
            const errorMessage = errors.map(x => `${x.tenantAssetId}: ${x.error.message}`).join('<br>');
            this._toastService.error(errorMessage, 'Error while deleting assets');
        } else {
            this._toastService.info('Assets processed successfully');
        }
    }
}
