<h2 matDialogTitle>
    Statistics for User Role Audit
    <span class="color-primary-400">{{ data.userRoleAuditName }}</span>
</h2>

<mat-dialog-content>
    <lib-spinner *ngIf="loading; else notLoading"></lib-spinner>
    <ng-template #notLoading>

        <div class="center">
            <!-- Simple table that displays the old and new sums in two rows -->
            <table>
                <tr>
                    <th></th>
                    <th>Full</th>
                    <th>Tenant Admin</th>
                    <th>Asset Architect</th>
                    <th>Asset Manager</th>
                    <th>Asset Reader</th>
                    <th>Asset User</th>
                </tr>
                <tr>
                    <td><b>Old</b></td>
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.userCountFull !== userRoleAuditStatistic.oldSums.userCountFull ? '#f44336' : ''}">
                        {{ userRoleAuditStatistic.oldSums.userCountFull }}
                    </td>
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.roleTenantAdminCount !== userRoleAuditStatistic.oldSums.roleTenantAdminCount ? '#f44336' : ''}">
                        {{ userRoleAuditStatistic.oldSums.roleTenantAdminCount }}
                    </td>
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.roleAssetArchitectCount !== userRoleAuditStatistic.oldSums.roleAssetArchitectCount ? '#f44336' : ''}">
                        {{ userRoleAuditStatistic.oldSums.roleAssetArchitectCount }}
                    </td>
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.roleAssetManagerCount !== userRoleAuditStatistic.oldSums.roleAssetManagerCount ? '#f44336' : ''}">
                        {{ userRoleAuditStatistic.oldSums.roleAssetManagerCount }}
                    </td>
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.roleAssetReaderCount !== userRoleAuditStatistic.oldSums.roleAssetReaderCount ? '#f44336' : ''}">
                        {{ userRoleAuditStatistic.oldSums.roleAssetReaderCount }}
                    </td>
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.roleAssetUserCount !== userRoleAuditStatistic.oldSums.roleAssetUserCount ? '#f44336' : ''}">
                        {{ userRoleAuditStatistic.oldSums.roleAssetUserCount }}
                    </td>
                </tr>
                <tr>
                    <td><b>New</b></td>
                    <!-- Display the new sums (Yellow tint if the new sum is different from the old sum) -->
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.userCountFull !== userRoleAuditStatistic.oldSums.userCountFull ? '#32cd32' : ''}">
                        {{ userRoleAuditStatistic.newSums.userCountFull }}
                    </td>
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.roleTenantAdminCount !== userRoleAuditStatistic.oldSums.roleTenantAdminCount ? '#32cd32' : ''}">
                        {{ userRoleAuditStatistic.newSums.roleTenantAdminCount }}
                    </td>
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.roleAssetArchitectCount !== userRoleAuditStatistic.oldSums.roleAssetArchitectCount ? '#32cd32' : ''}">
                        {{ userRoleAuditStatistic.newSums.roleAssetArchitectCount }}
                    </td>
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.roleAssetManagerCount !== userRoleAuditStatistic.oldSums.roleAssetManagerCount ? '#32cd32' : ''}">
                        {{ userRoleAuditStatistic.newSums.roleAssetManagerCount }}
                    </td>
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.roleAssetReaderCount !== userRoleAuditStatistic.oldSums.roleAssetReaderCount ? '#32cd32' : ''}">
                        {{ userRoleAuditStatistic.newSums.roleAssetReaderCount }}
                    </td>
                    <td
                        [ngStyle]="{'color': userRoleAuditStatistic.newSums.roleAssetUserCount !== userRoleAuditStatistic.oldSums.roleAssetUserCount ? '#32cd32' : ''}">
                        {{ userRoleAuditStatistic.newSums.roleAssetUserCount }}
                    </td>
                </tr>
            </table>

            <table *ngIf="(diffViewData.length > 0)">
                <tr>
                    <th>Diff State</th>
                    <th>Role ID</th>
                    <th>User OID</th>
                    <th>Created At</th>
                    <th>Created By</th>
                </tr>
                <tr *ngFor="let diff of diffViewData">
                    <td>{{ diff.snapshotDiffState }}</td>
                    <td>{{ diff.roleId }}</td>
                    <td>{{ diff.userEmail }}</td>
                    <td>{{ diff.createdAt | date: 'short' }}</td>
                    <td>{{ diff.createdByEmail }}</td>
                </tr>
            </table>
        </div>

    </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button (click)="cancel()">
        Cancel
    </button>
</mat-dialog-actions>