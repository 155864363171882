import { UserRoleOutput } from './userRoleOutput';

/**
 * RoleId mapping from the database
 * id	name
 * 1	Tenant Owner
 * 2	Tenant Admin
 * 3	Asset Architect
 * 4	Asset Manager
 * 5  Asset Reader
 * 6	Asset User
 * 7	SYS Admin
 */
export const RoleId = {
    TENANT_OWNER: 1,
    TENANT_ADMIN: 2,
    ASSET_ARCHITECT: 3,
    ASSET_MANAGER: 4,
    ASSET_READER: 5,
    ASSET_USER: 6,
    SYS_ADMIN: 7,
    USER: 8,
};

// Role lookup map
export const RoleLookup = new Map<number, string>([
    [RoleId.TENANT_OWNER, 'Tenant Owner'],
    [RoleId.TENANT_ADMIN, 'Tenant Admin'],
    [RoleId.ASSET_ARCHITECT, 'Asset Architect'],
    [RoleId.ASSET_MANAGER, 'Asset Manager'],
    [RoleId.ASSET_READER, 'Asset Reader'],
    [RoleId.ASSET_USER, 'Asset User'],
    [RoleId.SYS_ADMIN, 'SYS Admin'],
    [RoleId.USER, 'User'],
]);


export type RoleOutput = {
    id: number;
    name?: string;
    createdAt?: Date;

    // Relations
    userRoles?: UserRoleOutput[] | null;
};
