<h2 mat-dialog-title>Import Assets</h2>

<mat-dialog-content>
    <div class="flex-column">
        <lib-spinner *ngIf="loading.isOngoing; else notLoadingElement"></lib-spinner>
        <ng-template #notLoadingElement>
            <!-- ERROR LOADING-->
            <ng-container *ngIf="loadingError">
                <div>{{ loadingErrorMessage }}</div>
            </ng-container>

            <!-- NO ERROR LOADING -->
            <ng-container *ngIf="!loadingError">
                <!-- Import Results on top -->
                <ng-container *ngIf="importAssetOutput">
                    <div class="title mt-normal">Import Results</div>
                    <span class="mat-body">
                        Not all assets were imported. Check the details below.
                    </span>

                    <mtx-popover #popover="mtxPopover" [yOffset]="8" [position]="['after', 'above']">
                        <ng-template mtxPopoverContent let-skipped="skipped">
                            <div class="flex-column font-size-14">
                                <span class="mb-tiny">The following assets were <b>skipped</b>:</span>
                                <div *ngFor="let id of skipped">- {{ id }}</div>
                            </div>
                        </ng-template>
                    </mtx-popover>

                    <ul class="mat-body">
                        <li style="color:green">Imported: {{ importAssetOutput.inserted.length }}</li>
                        <li>
                            <div class="flex-row align-items-center gap-normal">
                                <div style="color: orange">Skipped: {{ importAssetOutput.skipped.length }}</div>
                                <span class="details" #popoverTrigger="mtxPopoverTrigger"
                                    [mtxPopoverTriggerFor]="popover" [mtxPopoverTriggerData]="{
                      skipped: importAssetOutput.skipped
                    }">
                                    details
                                </span>
                                <!-- <mat-icon #popoverTrigger="mtxPopoverTrigger" [mtxPopoverTriggerFor]="popover"
                                      [mtxPopoverTriggerData]="{skipped: importAssetOutput.skipped}">
                                      question_mark
                                  </mat-icon> -->
                            </div>
                        </li>
                        <li style="color: red" *ngIf="importAssetOutput.err">
                            Error: {{ importAssetOutput.err }}
                        </li>
                    </ul>
                </ng-container>

                <div class="title">Step 1: Download Excel Template</div>
                <span class="mat-body">
                    Before you can import any new assets, you need to download the
                    appropriate Excel template. Just click on the asset types below that
                    you want to be included in the template.
                </span>
                <mat-chip-listbox [hideSingleSelectionIndicator]="false" [multiple]="true" #templateListbox>
                    <mat-chip-option color="accent" *ngFor="let asset of assets" [value]="asset" [selectable]="true">{{
                        asset.name }}</mat-chip-option>
                </mat-chip-listbox>
                <button mat-flat-button color="accent" [disabled]="
            !templateListbox?.value?.length ||
            templateListbox?.value?.length === 0 ||
            downloadExcelTemplateActivity
          " (click)="onClickDownloadExcelTemplate(templateListbox.value)">
                    Download Excel
                </button>

                <div class="title mt-normal">Step 2: Select Import File</div>
                <span class="mat-body">
                    After filling out the template, please drop your file below.
                </span>
                <div>
                    <app-file-drop [(file)]="file" [accepts]="['xlsx']"></app-file-drop>
                </div>

                <!-- STEP 3 -->
                <div class="title mt-normal">Step 3: Choose Location</div>
                <span class="mat-body">
                    Each new asset must be located somewhere. Please assign an initial
                    location for the assets here.
                </span>
                <div class="form-field-density-1">
                    <lib-location-select label="Initial Asset Location" [required]="true"
                        [(locationId)]="selectedLocationId">
                    </lib-location-select>
                </div>
            </ng-container>
        </ng-template>

        <div class="error-message">
            {{ errorMessage }}
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <ng-container *ngIf="!loading.isOngoing">
        <button mat-stroked-button mat-dialog-close [disabled]="activity">
            Cancel
        </button>
        <button (click)="import()" mat-flat-button color="primary" [disabled]="!canImport || activity">
            <mat-icon *ngIf="activity" class="spinner">donut_large</mat-icon>
            <span> Import </span>
        </button>
    </ng-container>
</mat-dialog-actions>