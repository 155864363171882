<mtx-split direction="horizontal" unit="pixel" [gutterSize]="1">
    <mtx-split-pane>
        <div class="flex-column form-field-density-1">
            <!-- PROGRESS BAR -->
            <div style="height: 4px">
                <mat-progress-bar *ngIf="activity" mode="indeterminate"></mat-progress-bar>
            </div>

            <mat-form-field subscriptSizing="dynamic" class="element">
                <mat-label>Name</mat-label>
                <input #rtcNameInput matInput [ngModel]="rtc?.name"
                    (blur)="update(rtcNameInput.value, undefined, rtcNameInput)" [autocomplete]="uuid3"
                    [readonly]="!!rtc?.completedAt" />
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" class="element">
                <mat-label>Description</mat-label>
                <textarea #rtcDescriptionInput matInput [ngModel]="rtc?.description" [autocomplete]="uuid4"
                    [cdkTextareaAutosize]="true" [rows]="2" (blur)="
            update(undefined, rtcDescriptionInput.value, rtcDescriptionInput)
          " [readonly]="!!rtc?.completedAt"></textarea>
            </mat-form-field>

            <div *ngIf="assets.length > 0; else noAssetsArea" class="element flex-column">
                <div class="table-actions" style="color: white">
                    <mat-form-field style="margin: 5px;">
                        <mat-label>Search...</mat-label>
                        <input matInput (keyup)="applyFilter($event)" />
                    </mat-form-field>
                    <span class="flex-grow-1"></span>
                    <span *ngIf="selectedAssets.length > 0" class="font-size-14">
                        {{ selectedAssets.length }}
                        {{ selectedAssets.length === 1 ? "asset" : "assets" }} selected
                    </span>
                    <span class="flex-grow-1"></span>
                    <button mat-button [disabled]="!isSelectAll && !isSelectedAllIntermediate"
                        [class.disabled]="!isSelectAll && !isSelectedAllIntermediate" style="color: white"
                        (click)="removeSelectedAssets()">
                        <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                        <span>Remove</span>
                    </button>
                </div>

                <!-- ASSETS TABLE -->
                <table mat-table [dataSource]="assetTableDataSource" style="margin: 2px !important;">
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="custom-header">
                            <mat-checkbox *ngIf="!rtc?.completedAt" [ngModel]="isSelectAll"
                                [indeterminate]="isSelectedAllIntermediate"
                                (ngModelChange)="isSelectedAllChanged($event)"></mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox *ngIf="!rtc?.completedAt" [ngModel]="isSelected.get(row.id)"
                                (ngModelChange)="isSelectedChanged(row.id, $event)"></mat-checkbox>
                        </td>
                    </ng-container>
                    <!-- Asset ID Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef class="custom-header">Asset ID</th>
                        <td mat-cell *matCellDef="let row"> {{ row.id }} </td>
                    </ng-container>
                    <!-- Previously Located At Column -->
                    <ng-container matColumnDef="actionPreviouslyLocatedAt">
                        <th mat-header-cell *matHeaderCellDef class="custom-header">Previously Located
                            At</th>
                        <td mat-cell *matCellDef="let row"> {{ row.actionPreviouslyLocatedAt }} </td>
                    </ng-container>
                    <!-- Currently Located At Column -->
                    <ng-container matColumnDef="actionCurrentlyLocatedAt">
                        <th mat-header-cell *matHeaderCellDef class="custom-header">Currently Located At
                        </th>
                        <td mat-cell *matCellDef="let row"> {{ row.actionCurrentlyLocatedAt }} </td>
                    </ng-container>
                    <!-- Latest Booking By Column -->
                    <ng-container matColumnDef="actionLatestBookingBy">
                        <th mat-header-cell *matHeaderCellDef class="custom-header">Latest Booking By
                        </th>
                        <td mat-cell *matCellDef="let row"> {{ row.actionLatestBookingBy }} </td>
                    </ng-container>
                    <!-- Latest Booking Notes Column -->
                    <ng-container matColumnDef="actionLatestBookingNotes">
                        <th mat-header-cell *matHeaderCellDef class="custom-header">Latest Booking Notes
                        </th>
                        <td mat-cell *matCellDef="let row"> {{ row.actionLatestBookingNotes }} </td>
                    </ng-container>
                    <!-- Return From Customer Button -->
                    <ng-container matColumnDef="actionRfC">
                        <th mat-header-cell *matHeaderCellDef class="custom-header">Return From Customer</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-button color="primary" (click)="returnFromCustomer(row)">
                                <mat-icon>undo</mat-icon>
                                <span>Return</span>
                            </button>
                        </td>
                    </ng-container>

                    <!-- Header Definition -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

            </div>

            <ng-template #noAssetsArea>
                <span class="element">
                    All assets were removed. You can safely delete this 'Return to
                    Customer' if you do not need it anymore.
                </span>
            </ng-template>
        </div>
    </mtx-split-pane>

    <mtx-split-pane [size]="400">
        <div class="notes-area">
            <div class="flex-column gap-normal" style="
          padding-top: 8px;
          box-sizing: border-box;
          height: 100%;
          overflow-y: auto;
        ">
                <div *ngIf="rtcNotes.length === 0; else hasNotes"
                    class="align-self-center font-size-12 flex-row align-items-center justify-content-center height-100">
                    <div class="flex-column gap-normal align-items-center">
                        <span class="font-weight-500 font-size-16">There are no messages yet.</span>
                        <span>Type your first message below.</span>
                    </div>
                </div>
                <ng-template #hasNotes>
                    <div *ngFor="let note of rtcNotes" class="note-item">
                        <div class="note-item-header">
                            <lib-user [oId]="note.updatedBy ?? note.createdBy" [variant]="'phone'"></lib-user>
                            <span>
                                {{ note.updatedAt ?? note.createdAt | date : "MMM d, y HH:mm" }}
                            </span>
                            <span class="flex-grow-1"></span>
                            <span *ngIf="note.updatedBy">Edited</span>
                        </div>

                        <!-- NON EDIT MODE -->
                        <ng-container *ngIf="!notesEditMode.get(note.id)">
                            <div [mtxPopoverTriggerFor]="noteItemPopover" class="note-item-content">
                                {{ note.note }}
                            </div>
                            <!-- POPOVER -->
                            <mtx-popover #noteItemPopover="mtxPopover" [position]="['above', 'before']">
                                <div class="flex-row gap-small">
                                    <app-hover-icon icon="edit" color="primary" (click)="editNote(note)" tooltip="Edit"
                                        [disabled]="!!rtc?.completedAt"></app-hover-icon>

                                    <app-hover-icon icon="delete" color="warn" (click)="deleteNote(note)"
                                        tooltip="Delete" [disabled]="!!rtc?.completedAt"></app-hover-icon>
                                </div>
                            </mtx-popover>
                        </ng-container>

                        <!-- EDIT MODE -->
                        <ng-container *ngIf="notesEditMode.get(note.id)">
                            <mat-form-field subscriptSizing="dynamic" appearance="outline" class="form-field-density-3">
                                <textarea #editNoteTextarea matInput [cdkTextareaAutosize]="true" [ngModel]="note.note"
                                    [autocomplete]="uuid2" style="font-size: 14px"></textarea>
                            </mat-form-field>
                            <div class="mt-tiny align-self-end flex-row gap-small">
                                <app-hover-icon icon="close" tooltip="Cancel"
                                    (click)="notesEditMode.set(note.id, false)" color="primary"></app-hover-icon>
                                <app-hover-icon icon="done" tooltip="Done" color="primary"
                                    (click)="applyNoteText(note, editNoteTextarea.value)"></app-hover-icon>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </div>

            <div class="flex-column form-field-density-3 pl-small pr-small">
                <mat-form-field subscriptSizing="dynamic" appearance="outline">
                    <textarea matInput placeholder="Type a message" [(ngModel)]="newNote" [cdkTextareaAutosize]="true"
                        [autocomplete]="uuid1" style="max-height: 160px; font-size: 14px"
                        [disabled]="!!rtc?.completedAt"></textarea>
                </mat-form-field>
                <app-hover-icon icon="send" (click)="createNote()" color="primary" tooltip="Send"
                    class="align-self-end mt-tiny mb-tiny" [disabled]="!!rtc?.completedAt"></app-hover-icon>
            </div>
        </div>
    </mtx-split-pane>
</mtx-split>